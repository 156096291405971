<template>
	<v-container class="stretch" style="padding-top: 84px; height: 100%" fluid>
		<v-row class="stretch">
			<v-col cols="12">
				<v-row align="center" justify="center" class="headline stretch">
					<v-col>
						<v-row class="mb-4">
							<v-icon x-large class="margin-auto">fa fa-bomb</v-icon>
						</v-row>
						<v-row>
							<span class="margin-auto">Page Not Found</span>
						</v-row>
					</v-col>
				</v-row>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
	import {VueController} from "../service/support/vuex-controller";

	export default VueController('PageNotFound', {
		state: [
			"cognito"
		]
	});
</script>

<style scoped>

</style>
